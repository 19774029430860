window.onload = function onLoad() {
	setInterval("reloadInfo()",1000);
};

function reloadInfo() {
	if (pickingWinner == false) {
		$.ajax({
			type: "GET",
			url: app_url + "ajax/getRoundPlayers",
			success: function(msg){
				$("#RoundPlayers").text(msg);
			}
		});
	    $.ajax({
			type: "GET",
			url: app_url + "ajax/getRoundBank",
			success: function(msg){
				$("#RoundBank").text(msg);
	            document.title = "(" + msg + ") CSGODepot.net";
			}
		});
	    $.ajax({
			type: "GET",
			url: app_url + "ajax/getRoundItems",
			success: function(msg){
				$("#RoundItems").text(msg);
	            $(".progressbar").attr('data-value', msg);
	            updateProgressbar('.progressbar');
			}
		});

	    $.ajax({
	        type: "GET",
	        url: app_url + "ajax/getPlayerDeposit",
	        success: function(msg){
	            $("#PlayerDeposit").text(msg);
	        }
	    });
	    $.ajax({
	        type: "GET",
	        url: app_url + "ajax/getPlayerItems",
	        success: function(msg){
	            $("#PlayerItems").text(msg);
	        }
	    });
	    $.ajax({
	        type: "GET",
	        url: app_url + "ajax/getPlayerChance",
	        success: function(msg){
	            $("#PlayerChance").text(msg);
	        }
	    });

		$.ajax({
	        type: "GET",
	        url: app_url + "ajax/getTimeLeft",
	        success: function(msg){
	            $("#TimeLeft").text(msg);
				timeleft = msg;
				timeleft2 = 222 + timeleft;
				spinRoulette();
	        }
	    });

		$.ajax({
	        type: "GET",
	        url: app_url + "ajax/getRoundItemsList",
	        success: function(msg){
	            $(".items-wrap").html(msg);
	        }
	    });
		$.ajax({
	        type: "GET",
	        url: app_url + "ajax/getLogHTML",
	        success: function(msg){
	            $(".log-wrap").html(msg);
	        }
	    });
	}
}
