var mobileMenuVisible = false;
var overlayWrapCenter = ($(window).height() - $('.overlay .overlay-wrap').height()) / 2 - 25;
var autoresizeOverlay;

function getCookie(name) {
	var re = new RegExp(name + "=([^;]+)");
	var value = re.exec(document.cookie);
	return (value != null) ? unescape(value[1]) : null;
}

function dropdown() {
	$('.dropdown').slideToggle(250);
}

function prepareResponsive() {
	var sectionHeight = $(window).height() - ($('#header').height() + $('#footer').height());

	if ($('.container').length) {
		$('.container').css('height', sectionHeight + 'px');
		$('.container').css('margin-top', $('#header').height() + 'px');

		if ($(window).width() < 870) {
			$('#header #nav').hide();
			$('#header .menu-btn').show();
		}

		if ($(window).width() > 870) {
			$('#header #nav').show();
			$('#header .menu-btn').hide();
			if (mobileMenuVisible == false)
			{
				if ($(window).width() > 840 && $(window).width() < 980) {
					$('#header #menu').hide();
					$('.overlay').hide();
				}
			}
		}
	} else {
		// IF SMALLER
		if ($(window).width() < 980) {
			$('section:nth-child(2)').hide();
			$('section:nth-child(1)').css('width', '100%');
		}

		if ($(window).width() < 870) {
			$('#header #nav').hide();
			$('#header .menu-btn').show();
			$('#log').hide();
			$('#items').css('width', '100%');
			$('#items').css('border-left', 'none');
		}

		if ($(window).width() < 690) {
			$('#potinfo .info').css('display', 'block');
			$('#potinfo .info').css('width', '100%');
			$('#potinfo .info').css('height', '75px');
			$('#potinfo .info').css('border-right', 'none');
			$('#potinfo .info .row').css('width', '50%');
			$('#potinfo .info .row').css('display', 'inline-block');
			$('#potinfo .info .row .col').css('width', ($('#potinfo').width() / 6) - 1 + 'px');
			$('#potinfo .progress').css('display', 'block');
			$('#potinfo .progress').css('width', $(window).width());
			$('#potinfo .progress .timer').css('width', $('#potinfo .progress .timer span').width() + 5 + 'px');
			$('#potinfo .progress .timer').css('display', 'inline-block');
			$('#potinfo .progress .timer').css('float', 'left');
			$('#potinfo .progress .timer').css('margin-top', '0');
			$('#potinfo .progress .line').css('width', $('#potinfo').width() - $('#potinfo .progress .timer').width() - $('#potinfo .progress .button').width() - 85 + 'px');
			$('#potinfo .progress .line').css('display', 'inline-block');
			$('#potinfo .progress .line').css('float', 'left');
		}

		if ($(window).width() < 490) {
			$('#potinfo .info .row .col h4').css('font-size', '17px');
			$('#potinfo .info .row .col span').css('font-size', '8px');
			$('#potinfo .progress .button').css('font-size', '9px');
			$('#potinfo .progress .timer').css('width', $('#potinfo').width() - $('#potinfo .progress .button').width() - 65 + 'px');
			$('#potinfo .progress .line').hide();
		}

		// IF BIGGER
		if ($(window).width() > 980) {
			$('section:nth-child(2)').show();
			$('section:nth-child(1)').css('width', '75%');
		}

		if ($(window).width() > 870) {
			$('#header #nav').show();
			$('#header .menu-btn').hide();
			if (mobileMenuVisible == false)
			{
				if ($(window).width() > 840 && $(window).width() < 980) {
					$('#header #menu').hide();
					$('.overlay').hide();
				}
			}
			$('#log').show();
			$('#log').css('width', '50%');
			$('#items').css('width', '50%');
			$('#items').css('border-left', '1px solid #23333a');
		}

		if ($(window).width() > 690) {
			$('#potinfo .info').css('display', 'inline-block');
			$('#potinfo .info').css('width', '286px');
			$('#potinfo .info').css('height', '150px');
			$('#potinfo .info').css('border-right', '1px solid #23333a');
			$('#potinfo .info .row').css('width', '100%');
			$('#potinfo .info .row').css('display', 'block');
			$('#potinfo .info .row .col').css('width', '95px');
			$('#potinfo .progress').css('display', 'inline-block');
			$('#potinfo .progress').css('width', $('#potinfo').width() - 287 + 'px');
			$('#potinfo .progress .timer').css('width', '100%');
			$('#potinfo .progress .timer').css('display', 'block');
			$('#potinfo .progress .timer').css('float', '');
			$('#potinfo .progress .timer').css('margin-top', '15px');
			$('#potinfo .progress .line').css('width', '100%');
			$('#potinfo .progress .line').css('display', 'block');
			$('#potinfo .progress .line').css('float', '');
		}

		if ($(window).width() > 490) {
			$('#potinfo .info .row .col h4').css('font-size', '20px');
			$('#potinfo .info .row .col span').css('font-size', '11px');
			$('#potinfo .progress .button').css('font-size', '12px');
			$('#potinfo .progress .line').show();
		}

		$('section').each(function() {
			$(this).css('height', sectionHeight + 'px');
			$(this).css('margin-top', $('#header').height() + 'px');
		});

		$('#log').css('height', sectionHeight - 150 + 'px');
		$('#items').css('height', sectionHeight - 150 + 'px');
		$('#potinfo').css('height', 150 + 'px');
		$('#messages').css('height', sectionHeight - 70 + 'px');

		if (autoresizeOverlay == true) {
			overlayWrapCenter = ($(window).height() - $('.overlay .overlay-wrap').height()) / 2 - 25;
			$('.overlay .overlay-wrap').css('margin-top', overlayWrapCenter + 'px');
		}
	}
}

function updateProgressbar(progressbar) {
	if ($(progressbar).attr('data-value') == '0') {
		$(progressbar + ' span').html('');
		$(progressbar + ' span').hide();
		$(progressbar).css('width', '0');
	}
	else if ($(progressbar).attr('data-value') <= $(progressbar).attr('data-valuemax')) {
		$(progressbar).css('width', ($('.blue-line').width() / $(progressbar).attr('data-valuemax')) * $(progressbar).attr('data-value'))
		$(progressbar + ' span').html($(progressbar).attr('data-value'));
		$(progressbar + ' span').show();
	} else {
		$(progressbar).css('width', ($('.blue-line').width()));
		$(progressbar + ' span').html('50+');
		$(progressbar + ' span').show();
	}
}

function overlay() {
	this.show = function(title, message, nobtn, yesbtn, onYES, onNO, yesTXT, noTXT, canHide, enablePositioning) {
		var no, yes;

		if (enablePositioning == true) {
			autoresizeOverlay == true;
		} else {
			autoresizeOverlay == false;
		}

		if (nobtn == true) {
			no = '<a class="button no" onClick="Overlay.no(' + onNO + ');">' + noTXT + '</a>';
		} else {
			no = '';
		}

		if (yesbtn == true) {
			yes = '<a class="button yes" onClick="Overlay.yes(' + onYES + ');">' + yesTXT + '</a>';
		} else {
			yes = '';
		}

		$('.overlay .overlay-wrap .buttons').html(yes + no);

		$('.overlay .overlay-wrap h2').html(title);
		$('.overlay .overlay-wrap .content').html(message);

		$('.overlay').fadeIn(400);

		if (enablePositioning == true) {
			overlayWrapCenter = ($(window).height() - $('.overlay .overlay-wrap').height()) / 2 - 25;
			$('.overlay .overlay-wrap').css('margin-top', overlayWrapCenter + 'px');
		}

		if (canHide == true) {
			$('.overlay').click(function() {
				$('.overlay').fadeOut(400);
			});

			$('.overlay .overlay-wrap').click(function(event){
			    event.stopPropagation();
			});
		}
	}
	this.yes = function(callback) {
		callback();
	}
	this.no = function(callback) {
		$('.overlay').fadeOut(400);
		callback();
	}
}

var Overlay  = new overlay();
var Chat     = new chat();

function chatScrollBottom() {
	var messagesElement = document.getElementById('messages');

	messagesElement.scrollTop = messagesElement.scrollHeight;
	Ps.update(messagesElement);
}

var main = function () {
	if ($('.container').length) {
		prepareResponsive();

		var elements = document.getElementsByClassName('scrollable');
		var len = elements.length;
		if (len) {
			for (var i = 0; i < len; i++) {
				Ps.initialize(elements[i]);
			}
		}

		$('#header .menu-btn').click(function() {
	        $('#header .menu').toggle();
	        $('.overlay').toggle();

			if(mobileMenuVisible == true) {
				mobileMenuVisible == false;
			} else {
				mobileMenuVisible == true;
			}
	    });

		$(window).resize(function() {
			prepareResponsive();

			if (len) {
				for (var i = 0; i < len; i++) {
					Ps.update(elements[i]);
				}
			}
		});
	} else {
		Chat.Init();
		prepareResponsive();

		var elements = document.getElementsByClassName('scrollable');
		var len = elements.length;
		if (len) {
			for (var i = 0; i < len; i++) {
				Ps.initialize(elements[i]);
			}
		}

		chatScrollBottom();

		$("#send").keydown(function(event) {
			var key = event.which;
			if (key == 13) {
				event.preventDefault();
			}

			if (key >= 33) {
				var maxLength = 57;
				var length = this.value.length;
				if (length >= maxLength) {
					event.preventDefault();
				}
			}
		});

		$('#send').keyup(function(e) {
			if (e.keyCode == 13) {
				var text = $(this).val();
				var maxLength = $(this).attr("maxlength");
				var length = text.length;

				if (length <= maxLength + 1) {
					Chat.Send(text);

					$(this).val("");
				} else {
					$(this).val(text.substring(0, maxLength));
				}
			}
		});

		$('#potinfo .progress .button').click(function() {
			Overlay.show('Deposit',
				'<p>Minimum deposit is: ' + getCookie('minbet') + '$.</br>Maximum deposit is: ' + getCookie('maxitems') + ' items.</br></br>Are you sure you want to deposit?</p>', true, true,
				function() {
					window.open('https://steamcommunity.com/tradeoffer/new/?partner=233315559&token=8VX22wc-');
					$('.overlay').fadeOut(400);
				},
				function() { }, 'Accept', 'Decline', true, true);
		});

		$('#header .menu-btn').click(function() {
	        $('#header .menu').toggle();
	        $('.overlay').toggle();

			if(mobileMenuVisible == true) {
				mobileMenuVisible == false;
			} else {
				mobileMenuVisible == true;
			}
	    });

		updateProgressbar('.progressbar');

		$(window).resize(function() {
			prepareResponsive();

			if (len) {
				for (var i = 0; i < len; i++) {
					Ps.update(elements[i]);
				}
			}

			chatScrollBottom();
			updateProgressbar('.progressbar');
		});
	}
}

$(document).ready(main);
