var audioElement = document.createElement('audio');
audioElement.setAttribute('src', 'audio/audio.mp3');
var audioElement2 = document.createElement('audio');
audioElement2.setAttribute('src', 'audio/msg.mp3');
var audioElement3 = document.createElement('audio');
audioElement3.setAttribute('src', 'audio/open.wav');

var pickingWinner = false;

var circle;
var bets = 100500;
var ms = 1000;
var ls = 0;
var roulet = 0;
var clos = 0;
var timeleft = 0;
var timeleft = 0;

function spinRoulette() {
    if (timeleft == 0) {
        pickingWinner = true;

        $.ajax({
			type: "GET",
			url: app_url + "ajax/getSpinner",
			success: function(msg) {
                Overlay.show('Selecting a winner...', msg, false, false, function() {}, function() {}, '', '', false, false);

                overlayWrapCenter = ($(window).height() - 315) / 2 - 25;
        		$('.overlay .overlay-wrap').css('margin-top', overlayWrapCenter + 'px');

                $('.overlay .overlay-wrap .content').css('width', '184px');
                $('.overlay .overlay-wrap .content').css('border', '2px solid #3CF');
                $('.overlay .overlay-wrap .content').css('border-radius', '3px');
                $('.overlay .overlay-wrap .content').css('box-sizing', 'content-box');

                $(window).on('resize', function() {
                    overlayWrapCenter = ($(window).height() - 315) / 2 - 25;
            		$('.overlay .overlay-wrap').css('margin-top', overlayWrapCenter + 'px');
                });

                var options = {
                	speed : 5,
                	duration : 5,
                	stopImageNumber : 0,
                	startCallback     : function() {  },
                	slowDownCallback  : function() {  },
                	stopCallback      : function($stopElm) {
                		setTimeout(function() {
                            pickingWinner = false;
                            Overlay.show('The winner is:', $stopElm, false, true, function() {
                                $('.overlay').fadeOut(400);

                                $('.overlay .overlay-wrap .content').css('width', '100%');
                                $('.overlay .overlay-wrap .content').css('border', 'none');
                                $('.overlay .overlay-wrap .content').css('border-radius', 'none');
                                $('.overlay .overlay-wrap .content').css('box-sizing', 'border-box');
                            }, function() {}, 'OK', '', true, true);
                        }, 1500);
                        roulet = 0;
                	}
                }

                $('.roulette').roulette(options);
                $('.roulette').roulette('start');
			}
		});
    }
}
