function chat() {
    this.Init = function() {

        var wsUri = "wss://csgodepot.net:9000";
        websocket = new WebSocket(wsUri, 'webchat');

        var url = "ws://" + document.URL.substr(7).split('/')[0];

        //websocket.onopen      = function(ev) {  }
        //websocket.onerror	    = function(ev) {  }
        //websocket.onclose 	= function(ev) {  }

        websocket.onmessage = function(ev) {
            var msg      = JSON.parse(ev.data);
            var id       = msg.id;
            var admin    = msg.admin;
            var avatar   = msg.avatar;
            var username = msg.username;
            var message  = msg.message;
            var steamid  = msg.steamid;

            var adm;

            if (admin == 1) {
                adm = '<a class="name admin" href="#">&#9813; ' + username + '</a>';
            } else {
                adm = '<a target="_blank" href="http://steamcommunity.com/profiles/' + steamid + '" class="name">' + username + '</a>';
            }

            if (msg.id != null) {
                $('.messages-wrap').append(
                      '<div class="message">'
                    + '<div class="avatar" style="background-image: url(\'' + avatar + '\');"></div>'
                    + '<div class="user">'
                    + adm
                    + '<span class="text">' + message + '</span>'
                    + '</div>'
                    + '</div>');

                var messagesElement = document.getElementById('messages');

            	messagesElement.scrollTop = messagesElement.scrollHeight;
            	Ps.update(messagesElement);
            }
        }
    }
    this.Send = function(msg) {
        var mssg = $.trim(msg);
        if (mssg) {
            var mssg = {
                id       : getCookie('id'),
                admin    : getCookie('admin'),
                username : getCookie('name'),
                avatar   : getCookie('avatar'),
                steamid  : getCookie('steamid'),
                message  : mssg
            };

            websocket.send(JSON.stringify(mssg));
        }
    }
}
